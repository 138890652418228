var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('figure',{staticClass:"card",on:{"mouseover":_vm.showAllActions,"mouseleave":_vm.hideAllActions}},[(
        _vm.folderMediaData.mediaTypeToken == _vm.imageToken ||
        _vm.folderMediaData.mediaTypeToken == ''
      )?_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.NewsImgZoom",modifiers:{"NewsImgZoom":true}}],staticClass:"mainImg",attrs:{"src":_vm.mediaSrc,"onerror":("this.src='" + _vm.defaultImg + "'"),"alt":"Image"},on:{"click":function($event){return _vm.setFolderMediaData()}}}):_vm._e(),(_vm.folderMediaData.mediaTypeToken == _vm.videoToken)?_c('b-icon',{attrs:{"icon":"play-circle","animation":"throb","variant":"danger","scale":"0.9","font-scale":"5","id":"play-icon"}}):_vm._e(),(_vm.folderMediaData.mediaTypeToken == _vm.videoToken)?_c('video',{directives:[{name:"b-modal",rawName:"v-b-modal.VideoShowModal",modifiers:{"VideoShowModal":true}}],staticClass:"video-container",on:{"click":function($event){return _vm.setFolderMediaData()}}},[_c('source',{attrs:{"src":_vm.mediaSrc,"type":"video/mp4","onerror":("this.src='" + _vm.defaultImg + "'")}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e(),_c('footer',{directives:[{name:"show",rawName:"v-show",value:(_vm.showActions),expression:"showActions"}]},[_c('div',{staticClass:"card-actions"},[_c('router-link',{attrs:{"to":{
            name: 'EmployeesFoldersMediaEdit',
            params: {
              folderMediaToken: _vm.folderMediaData.folderMediaToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.FolderMediaDelete",modifiers:{"FolderMediaDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setFolderMediaData(_vm.folderMediaData)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])],1)]),(_vm.folderMediaData.folderMediaNameCurrent)?_c('router-link',{attrs:{"to":"#","title":_vm.folderMediaData.folderMediaNameCurrent}},[_c('figcaption',[_c('h3',{staticClass:"cut-2line"},[_vm._v(" "+_vm._s(_vm.folderMediaData.folderMediaNameCurrent)+" ")])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }